<template>
    <div>
        <div
            class="tabTit fs-3 container d-flex align-items-center justify-content-center border-bottom border-secondary wow animate__bounceInDown">
            <span>Partner</span>
        </div>
        <div class="PartnerBox d-flex container flex-wrap justify-content-center">
            <div class="PartnerBox_item wow animate__flipInX" v-for="i, x in list" :key="x">
                <img class="p_img" :src="i" alt="">
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';


const list = ref([
    require('../assets/image/101.png'),
    require('../assets/image/102.png'),
    require('../assets/image/103.png'),
    require('../assets/image/104.png'),
    require('../assets/image/105.png'),
    require('../assets/image/106.png'),
    require('../assets/image/107.png'),
    require('../assets/image/108.png')
])
</script>

<style lang="scss" scoped>
.PartnerBox {
    padding-bottom: 300px;

    .PartnerBox_item {
        width: 198px;
        height: 199px;
        background-color: #fff;
        margin: 0 20px;
        margin-bottom: 40px;

        .p_img {
            width: 100%;
            height: 100%;
        }
    }
}

@media (max-width: 1199.98px) {
    $num: 1.5;

    .PartnerBox {
        padding-bottom: calc(300px / $num);

        .PartnerBox_item {
            width: calc(198px / $num);
            height: calc(199px / $num);
            background-color: #fff;
            margin: 0 calc(20px / $num);
            margin-bottom: calc(40px / $num);

            .p_img {
                width: 100%;
                height: 100%;
            }
        }
    }
}

@media (max-width: 992px) {
    $num: 2;

    .PartnerBox {
        padding-bottom: calc(300px / $num);

        .PartnerBox_item {
            width: calc(198px / $num);
            height: calc(199px / $num);
            background-color: #fff;
            margin: 0 calc(20px / $num);
            margin-bottom: calc(40px / $num);

            .p_img {
                width: 100%;
                height: 100%;
            }
        }
    }
}

@media (max-width: 767.98px) {
    $num: 3;

    .PartnerBox {
        padding-bottom: calc(300px / $num);

        .PartnerBox_item {
            width: calc(198px / $num);
            height: calc(199px / $num);
            background-color: #fff;
            margin: 0 calc(20px / $num);
            margin-bottom: calc(40px / $num);

            .p_img {
                width: 100%;
                height: 100%;
            }
        }
    }
}

@media (max-width: 576px) {
    $num: 3.5;

    .PartnerBox {
        padding-bottom: calc(300px / $num);

        .PartnerBox_item {
            width: calc(198px / $num);
            height: calc(199px / $num);
            background-color: #fff;
            margin: 0 calc(20px / $num);
            margin-bottom: calc(40px / $num);

            .p_img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>