<template>
    <div>
        <div class="tabTit fs-3 container  d-flex align-items-center justify-content-center wow animate__bounceInDown">
            <span>Development Stages</span>
        </div>
        <div class="StageBox" ref="StageBox" @click.stop>
            <div class="st_nei">
                <div class="st_top d-flex">
                    <template v-for="i, x in list" :key="x">
                        <div class="st_b d-flex align-items-center justify-content-center wow animate__backInDown"
                            :class="x % 2 == 0 ? 'st_b1' : ''">
                            <div v-if="x % 2 == 0">
                                <div class="st_t">{{ i.t1 }}</div>
                                <div class="st_t1">{{ i.t2 }}</div>
                            </div>
                            <img v-else class="zuoBiao zou_b" src="../assets/image/zuoBiao.png" alt=""
                                draggable="false">
                        </div>
                    </template>
                </div>
                <div class="st_xian"></div>
                <div class="st_top d-flex">
                    <template v-for="i, x in list" :key="x">
                        <div class="st_b d-flex align-items-center justify-content-center wow animate__backInUp"
                            :class="x % 2 == 0 ? '' : 'st_b2'">
                            <img v-if="x % 2 == 0" class="zuoBiao zou_t" src="../assets/image/zuoBiao.png" alt=""
                                draggable="false">
                            <div v-else>
                                <div class="st_t">{{ i.t1 }}</div>
                                <div class="st_t1">{{ i.t2 }}</div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';

const StageBox = ref(null);
const flag = ref(null);
const downX = ref(null);
const scrollLeft = ref(null);

const list = ref([
    {
        t1: 'Q3 2023',
        t2: '- Formation of the TON-HorseGameFi team\n- Development of TON-HorseGameFi technology applications',
    },
    {
        t1: 'Q4 2023',
        t2: '- Secured angel investment\n- TON-HorseGameFi testing phase',
    },
    {
        t1: 'Q1 2024',
        t2: '- Official launch of TON-HorseGameFi 1.0\n- MSB certification\n- Official company operations certification',
    },
    {
        t1: 'Q2 2024',
        t2: '- Official website launch\n- Launch of Killer Royale game\n- Launch of Crash game',
    },
    {
        t1: 'Q3 2024',
        t2: '- Official launch of TON-HorseGameFi 2.0\n- Community airdrop of $HORSE\n- HORSE-NFT Staking\n- Launch of ambassador NFTs\n- Pre-market trading launch',
    },
    {
        t1: 'Q4 2024',
        t2: '- Staking mining launch\n- Horse racing game launch\n- Ganesha Gold game launch\n- Tap Horse Coin game launch\n- Public launch of Horse leaderboard\n- NFT mining reduced by 50%',
    },
    {
        t1: 'Q1 2025',
        t2: '- Execution of $HORSE burn\n- $HORSE trading launch (Listing price: 0.005 USDT/HORSE)\n- Community voting rights for public games',
    },
    {
        t1: 'Q2 2025',
        t2: '- Official launch of TON-HorseGameFi 3.0\n- Establishment of the HORSE Foundation\n- Community call for the best game launch',
    },
    {
        t1: 'Q3 2025',
        t2: '- Distribution platform for the best TON chain games\n- Community vote to select 3 HORSE game guilds',
    },
    {
        t1: 'Q4 2025',
        t2: '- Coming soon.',
    },
])

onMounted(() => {
    StageBox.value.addEventListener('selectstart', function (e) {
        e.preventDefault();
    })

    StageBox.value.addEventListener("mousedown", function (event) {
        flag.value = true;
        downX.value = event.clientX;
        scrollLeft.value = this.scrollLeft;
    })

    StageBox.value.addEventListener("mousemove", function (event) {
        if (flag.value) {
            let moveX = event.clientX;
            let scrollX = moveX - downX.value;
            this.scrollLeft = scrollLeft.value - scrollX;
        }
    })

    StageBox.value.addEventListener("mouseup", function () {
        flag.value = false;
    })

    StageBox.value.addEventListener("mouseleave", function () {
        flag.value = false;
    })
})


</script>

<style lang="scss" scoped>

.StageBox {
    overflow-x: scroll;

    &::-webkit-scrollbar {
        display: none;
    }

    .st_nei {
        display: inline-block;

        .st_top {
            margin: 0 auto;
            width: fit-content;

            .st_b {
                width: 306px;
                height: 287px;
                position: relative;

                .st_t {
                    font-size: 26px;
                }

                .st_t1 {
                    font-size: 16px;
                    white-space: pre-wrap;
                }

                .zuoBiao {
                    width: 100px;
                    height: 100px;
                }

                .zou_b {
                    position: absolute;
                    bottom: -60px;
                }

                .zou_t {
                    position: absolute;
                    top: -60px;
                    transform: rotate(180deg);
                }
            }

            .st_b1 {
                background-image: linear-gradient(0deg,
                        #290440 0%,
                        #1f0331 24%,
                        #000000 100%);
            }

            .st_b2 {
                background-image: linear-gradient(0deg,
                        #000000 0%,
                        #1c042b 64%,
                        #2c0544 100%);
            }
        }

        .st_xian {
            width: 100%;
            height: 21px;
            background-color: #2d084f;
        }
    }
}
</style>