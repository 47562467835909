<template>
    <div class="">
        <div class="BottomBarBox container d-flex align-items-center justify-content-between">
            <div>
                Copyright © 2024. Horse
            </div>
            <div class="riBox">
                <a href="mailto://horsenft@yahoo.com">
                    <img src="../assets/image/link.png" alt="">
                </a>
                <a href="https://x.com/TON_HorseGAMEFI">
                    <img src="../assets/image/link1.png" alt="">
                </a>
                <a href="https://t.me/HorseNFT_Channel">
                    <img src="../assets/image/link2.png" alt="">
                </a>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.BottomBarBox {
    padding-bottom: 100px;
    font-size: 14px;

    .riBox {
        a {
            img {
                width: 30px;
                height: 30px;
                margin-left: 10px;
            }
        }
    }
}
</style>