<template>
    <div>
        <div
            class="tabTit fs-3 container d-flex align-items-center justify-content-center border-bottom border-secondary wow animate__bounceInDown">
            <span>Deflationary Model</span>
        </div>
        <div class="pyramidBox container d-flex justify-content-end">
            <img class="py_img" src="../assets/image/pyramid.png" alt="">
            <div class="py_l1 d-flex justify-content-end align-items-center text-end wow animate__zoomInLeft">
                Blockchain game ecosystem consumption
            </div>
            <div class="py_l2 d-flex justify-content-end align-items-center text-end wow animate__zoomInLeft"
                style="animation: timeline 1.1s;">
                Transaction fee burn
            </div>
            <div class="py_l3 d-flex justify-content-end align-items-center text-end wow animate__zoomInLeft"
                style="animation: timeline 1.2s;">
                DEX liquidity pool burn
            </div>
            <div class="py_l4 d-flex justify-content-end align-items-center text-end wow animate__zoomInLeft"
                style="animation: timeline 1.3s;">
                Mandatory token lock-up for game releases
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.pyramidBox {
    position: relative;
    height: 653px;

    .py_img {
        position: absolute;
        top: 0;
        right: 0;
        width: 646px;
        height: 653px;
        z-index: 2;
    }

    .py_l1 {
        position: absolute;
        top: 0;
        right: 324px;
        height: 247px;
        background-image: linear-gradient(90deg, transparent 0%, rgba($color: #4c1cd4, $alpha: 0.5) 100%);
        font-size: 32px;
        padding-right: 100px;
    }

    .py_l2 {
        position: absolute;
        top: 254px;
        right: 324px;
        height: 101px;
        background-image: linear-gradient(90deg, transparent 0%, rgba($color: #5917c0, $alpha: 0.5) 100%);
        font-size: 32px;
        padding-right: 204px;
    }

    .py_l3 {
        position: absolute;
        top: 363px;
        right: 324px;
        height: 96px;
        background-image: linear-gradient(90deg, transparent 0%, rgba($color: #e679c4, $alpha: 0.5) 100%);
        font-size: 32px;
        padding-right: 270px;
    }

    .py_l4 {
        position: absolute;
        top: 466px;
        right: 324px;
        height: 120px;
        background-image: linear-gradient(90deg, transparent 0%, rgba($color: #e53072, $alpha: 0.5) 100%);
        font-size: 32px;
        padding-right: 330px;
    }
}


@media (max-width: 1219.98px) {
    $num: 1.5;

    .pyramidBox {
        height: calc(653px / $num);

        .py_img {
            width: calc(646px / $num);
            height: calc(653px / $num);
        }

        .py_l1 {
            right: calc(324px / $num);
            height: calc(247px / $num);
            font-size: calc(32px / $num);
            padding-right: calc(100px / $num);
        }

        .py_l2 {
            top: calc(254px / $num);
            right: calc(324px / $num);
            height: calc(101px / $num);
            font-size: calc(32px / $num);
            padding-right: calc(204px / $num);
        }

        .py_l3 {
            top: calc(363px / $num);
            right: calc(324px / $num);
            height: calc(96px / $num);
            font-size: calc(32px / $num);
            padding-right: calc(270px / $num);
        }

        .py_l4 {
            top: calc(466px / $num);
            right: calc(324px / $num);
            height: calc(120px / $num);
            font-size: calc(32px / $num);
            padding-right: calc(330px / $num);
        }
    }
}

@media (max-width: 992px) {
    $num: 2;

    .pyramidBox {
        height: calc(653px / $num);

        .py_img {
            width: calc(646px / $num);
            height: calc(653px / $num);
        }

        .py_l1 {
            right: calc(324px / $num);
            height: calc(247px / $num);
            font-size: calc(32px / $num);
            padding-right: calc(100px / $num);
        }

        .py_l2 {
            top: calc(254px / $num);
            right: calc(324px / $num);
            height: calc(101px / $num);
            font-size: calc(32px / $num);
            padding-right: calc(204px / $num);
        }

        .py_l3 {
            top: calc(363px / $num);
            right: calc(324px / $num);
            height: calc(96px / $num);
            font-size: calc(32px / $num);
            padding-right: calc(270px / $num);
        }

        .py_l4 {
            top: calc(466px / $num);
            right: calc(324px / $num);
            height: calc(120px / $num);
            font-size: calc(32px / $num);
            padding-right: calc(330px / $num);
        }
    }
}

@media (max-width: 767.98px) {
    $num: 3;

    .pyramidBox {
        height: calc(653px / $num);

        .py_img {
            width: calc(646px / $num);
            height: calc(653px / $num);
        }

        .py_l1 {
            right: calc(324px / $num);
            height: calc(247px / $num);
            font-size: calc(32px / $num);
            padding-right: calc(100px / $num);
        }

        .py_l2 {
            top: calc(254px / $num);
            right: calc(324px / $num);
            height: calc(101px / $num);
            font-size: calc(32px / $num);
            padding-right: calc(204px / $num);
        }

        .py_l3 {
            top: calc(363px / $num);
            right: calc(324px / $num);
            height: calc(96px / $num);
            font-size: calc(32px / $num);
            padding-right: calc(270px / $num);
        }

        .py_l4 {
            top: calc(466px / $num);
            right: calc(324px / $num);
            height: calc(120px / $num);
            font-size: calc(32px / $num);
            padding-right: calc(330px / $num);
        }
    }
}

@media (max-width: 576px) {
    $num: 3.5;

    .pyramidBox {
        height: calc(653px / $num);

        .py_img {
            width: calc(646px / $num);
            height: calc(653px / $num);
        }

        .py_l1 {
            right: calc(324px / $num);
            height: calc(247px / $num);
            font-size: calc(32px / $num);
            padding-right: calc(100px / $num);
        }

        .py_l2 {
            top: calc(254px / $num);
            right: calc(324px / $num);
            height: calc(101px / $num);
            font-size: calc(32px / $num);
            padding-right: calc(204px / $num);
        }

        .py_l3 {
            top: calc(363px / $num);
            right: calc(324px / $num);
            height: calc(96px / $num);
            font-size: calc(32px / $num);
            padding-right: calc(270px / $num);
        }

        .py_l4 {
            top: calc(466px / $num);
            right: calc(324px / $num);
            height: calc(120px / $num);
            font-size: calc(32px / $num);
            padding-right: calc(330px / $num);
        }
    }
}
</style>