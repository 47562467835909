<template>
  <div>
    <HelloWorld />
    <TablesVue />
    <HorseBoxVue />
    <PyramidBoxVue />
    <GameBox />
    <StageBox />
    <JoinUsVue />
    <PartnerBox />
    <BottomBarBox />
  </div>
</template>

<script setup>
import HelloWorld from './components/HelloWorld.vue'
import TablesVue from './components/TablesBox.vue'
import HorseBoxVue from './components/HorseBox.vue'
import PyramidBoxVue from './components/PyramidBox.vue'
import GameBox from './components/GameBox.vue'
import StageBox from './components/StageBox.vue'
import JoinUsVue from './components/JoinUs.vue'
import PartnerBox from './components/PartnerBox.vue'
import BottomBarBox from './components/BottomBarBox.vue'

import { WOW } from 'wowjs'
import { onMounted } from 'vue'

onMounted(() => {
  new WOW({
    boxClass: 'wow',
    animateClass: 'animate__animated',
    offset: 10,
    mobile: true,
    live: true,
  }).init()
})
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
  background-color: black;
}

.selector-for-some-widget {
  box-sizing: content-box;
}
</style>
