<template>
    <div>
        <div
            class="tabTit fs-3 container d-flex align-items-center justify-content-center border-bottom border-secondary wow animate__bounceInDown">
            <img class="tabTit_img" src="../assets/image/gaoZi.png" alt="">
            <span>NFT Mining</span>
        </div>
        <div class="tabBox container text-center d-none d-xl-block d-lg-none">
            <table class="table table-dark table-bordered">
                <thead>
                    <tr>
                        <th class="th_height" valign="middle" v-for="i, x in tabHaed" :key="x">{{ i }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="i, x in tabList" :key="x">
                        <td v-for="iz, xz in i" :key="xz" class="td_height" valign="middle" :rowspan="iz.rowspan"
                            :colspan="iz.colspan"
                            :style="{ backgroundImage: `url(${iz.img})`, backgroundSize: '100% 100%' }">
                            {{ iz.text }}
                            <a :href="iz.link" v-if="iz.link">
                                <img class="link_img" src="../assets/image/link2.png" alt="">
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="d-xl-none StageBox" ref="StageBox" @click.stop>
            <div class="d-flex" style="width: fit-content;">
                <div class="card card_box" v-for="i, x in min_tabList" :key="x">
                    <div class="card-body">
                        <h5 class="card-title">{{ i.type }}</h5>
                        <img :src="i.img" class="card_img rounded mx-auto d-block" draggable="false">
                        <p v-show="i.level">
                            <span style="color: #979EAB;">Level:</span>
                            <span>{{ i.level }}</span>
                        </p>
                        <p v-show="i.price">
                            <span style="color: #979EAB;">Price (USDT):</span>
                            <span>{{ i.price }}</span>
                        </p>
                        <p v-show="i.Horse">
                            <span style="color: #979EAB;">Horse Tokens Generated:</span>
                            <span>{{ i.Horse }}</span>
                        </p>
                        <p v-show="i.titie">
                            <span>{{ i.titie }}</span>
                            <a :href="i.link" v-if="i.link">
                                <img class="card_link_img" src="../assets/image/link2.png" alt="">
                            </a>
                        </p>
                        <p v-show="i.cycle">
                            <span style="color: #979EAB;">Cycle (day):</span>
                            <span>{{ i.cycle }}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";

const tabHaed = ref(['Type', 'Level', 'Price (USDT)', 'Horse Tokens Generated', 'Cycle (day)'])
const tabList = ref([
    [
        {
            text: 'Experience NFT',
        },
        {
            text: 'N',
            img: require('../assets/image/nftN.png')
        },
        {
            text: '10',
        },
        {
            text: '24,960',
        },
        {
            text: '7',
        },
    ],
    [
        {
            text: 'Mining NFT',
            rowspan: '5'
        },
        {
            text: 'R',
            img: require('../assets/image/nftS.png')
        },
        {
            text: '100',
        },
        {
            text: '252,000',
        },
        {
            text: '15',
        },
    ],
    [
        {
            text: 'SR',
            img: require('../assets/image/nftSR.png')
        },
        {
            text: '500',
        },
        {
            text: '1,284,000',
        },
        {
            text: '30',
        },
    ],
    [
        {
            text: 'SSR',
            img: require('../assets/image/nftSSR.png')
        },
        {
            text: '1,000',
        },
        {
            text: '2,616,000',
        },
        {
            text: '60',
        },
    ],
    [
        {
            text: 'PR',
            img: require('../assets/image/nftPR.png')
        },
        {
            text: '2,000',
        },
        {
            text: '5,376,000',
        },
        {
            text: '90',
        },
    ],
    [
        {
            text: 'UR',
            img: require('../assets/image/nftUR.png')
        },
        {
            text: '3,000',
        },
        {
            text: '8,280,000',
        },
        {
            text: '120',
        },
    ],
    [
        {
            text: 'Genesis NFT',
        },
        {
            text: 'Genesis NFT',
            img: require('../assets/image/nftCS.png')
        },
        {
            text: 'Genesis NFT is a credential for ambassadors. It can generate HORSE tokens and grants voting rights.',
            colspan: '2',
            link: 'https://t.me/Horseservice',
        },
        {
            text: '90',
        },
    ],
    [
        {
            text: 'Note: After 1 billion Horse tokens are generated, production will be reduced!',
            colspan: '5'
        },
    ],
])
const min_tabList = ref([
    {
        type: 'Experience NFT',
        img: require('../assets/image/min_nftN.png'),
        level: 'N',
        price: '10',
        cycle: '7',
        Horse: '24,960'
    },
    {
        type: 'Mining NFT',
        img: require('../assets/image/min_nftR.png'),
        level: 'R',
        price: '100',
        cycle: '15',
        Horse: '252,000'
    },
    {
        type: 'Mining NFT',
        img: require('../assets/image/min_nftSR.png'),
        level: 'SR',
        price: '500',
        cycle: '30',
        Horse: '1,284,000	'
    },
    {
        type: 'Mining NFT',
        img: require('../assets/image/min_nftSSR.png'),
        level: 'SSR',
        price: '1,000',
        cycle: '60',
        Horse: '2,616,000'
    },
    {
        type: 'Mining NFT',
        img: require('../assets/image/min_nftPR.png'),
        level: 'PR',
        price: '2,000',
        cycle: '90',
        Horse: '5,376,000'
    },
    {
        type: 'Mining NFT',
        img: require('../assets/image/min_nftUR.png'),
        level: 'UR',
        price: '3,000',
        cycle: '120',
        Horse: '8,280,000'
    },
    {
        type: 'Experience NFT',
        img: require('../assets/image/min_nftCS1.png'),
        level: 'Genesis NFT',
        cycle: '90',
        titie: 'Genesis NFT is a credential for ambassadors. It can generate HORSE tokens and grants voting rights.',
        link: 'https://t.me/Horseservice',
    },
])

const StageBox = ref(null);
const flag = ref(null);
const downX = ref(null);
const scrollLeft = ref(null);

onMounted(() => {
    StageBox.value.addEventListener('selectstart', function (e) {
        e.preventDefault();
    })

    StageBox.value.addEventListener("mousedown", function (event) {
        flag.value = true;
        downX.value = event.clientX;
        scrollLeft.value = this.scrollLeft;
    })

    StageBox.value.addEventListener("mousemove", function (event) {
        if (flag.value) {
            let moveX = event.clientX;
            let scrollX = moveX - downX.value;
            this.scrollLeft = scrollLeft.value - scrollX;
        }
    })

    StageBox.value.addEventListener("mouseup", function () {
        flag.value = false;
    })

    StageBox.value.addEventListener("mouseleave", function () {
        flag.value = false;
    })
})
</script>

<style lang="scss" scoped>

.tabBox {

    td,
    th {
        background-color: transparent !important;
    }

    .th_height {
        height: 127px;
        width: 240px;
    }

    .td_height {
        height: 56px;
    }

    .link_img {
        width: 20px;
        height: 20px;
    }
}

.StageBox {
    overflow-x: scroll;

    &::-webkit-scrollbar {
        display: none;
    }

    .card_box {
        width: 18rem;
        display: inline-block;
        background: linear-gradient(180deg, #020a21 0%, #351043 100%);
        background-blend-mode: normal;
        border: 1px solid #2d255b;
        margin: 0 6px;

        .card_img {
            width: 80px;
            height: 80px;
            margin-bottom: 10px;
        }

        .card_link_img {
            width: 20px;
            height: 20px;
        }
    }
}
</style>
