<template>
  <div class="">
    <div class="hoadTop pb-3">
      <div class="hoad_title fs-3 d-flex align-items-center justify-content-center">
        <img class="rounded hoad_img" src="https://static.horse8.org/app/logo/256x256.png" alt="...">
        <span class="ms-1">TON Horse Game Fi</span>
      </div>
      <button type="button" class="btn btn-primary hoad_btn fs-1 wow animate__pulse"
        style="animation-iteration-count:infinite">TON Horse Game FI </button>
      <div class="hoad_maxTit fs-2 wow animate__bounceInDown">
        Building the best GameFi platform on the TON chain
      </div>
      <div class="hoad_bbox">
        <div class="hoad_minTit fs-4">
          Game application scenarios, earn profits through NFT mining, and enjoy dividends by holding Horse tokens.
        </div>
        <div class="hoad_btnAll">
          <a href="https://t.me/TopGameFiBot">
            <button type="button" class="btn btn-dark hoad_b1 btn-lg">Get started</button>
          </a>
          <a href="https://t.me/Horseservice">
            <button type="button" class="btn btn-dark hoad_b2 btn-lg">Contact us</button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.hoadTop {
  background-image: url(../assets/image/topBgc.png);
  background-position: center;
  height: 100vh;
  position: relative;

  .hoad_title {
    height: 50px;
    background-color: rgba($color: #000000, $alpha: 0.2);
    color: #5245e0;

    .hoad_img {
      width: 30px;
      height: 30px;
    }
  }

  .hoad_btn {
    margin-top: 86px;
    border-radius: 25px;
    border: none;
    font-weight: 700;
    background: linear-gradient(90deg, #0198ea 51.35%, #14bcfa 80.71%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .hoad_maxTit {
    color: rgba($color: #fff, $alpha: 0.6);
  }

  .hoad_bbox {
    width: 98vw;
    position: absolute;
    bottom: 60px;

    .hoad_minTit {
      // margin-top: 283px;
    }

    .hoad_btnAll {
      margin-top: 89px;

      .hoad_b1 {
        background-image: linear-gradient(90deg, #dd5ebb 0%, #4a55ff 100%);
      }

      .hoad_b2 {
        background-color: #33294b;
        margin-left: 18px;
      }
    }
  }

}


@media (min-width: 950px) {
  .hoadTop {
    .hoad_btn {
      font-size: 90px !important;
    }
  }
}

@media (min-width: 768px) {
  .hoadTop {
    .hoad_btn {
      font-size: 60px !important;
    }
  }
}

@media (max-width: 576px) {
  .hoadTop {
    .hoad_btn {
      font-size: 30px !important;
    }

    .hoad_bbox {

      .hoad_btnAll {
        margin-top: 40px;
      }
    }

    .hoad_maxTit {
      font-size: 16px !important;
    }

    .hoad_minTit {
      font-size: 16px !important;
    }
  }
}
</style>