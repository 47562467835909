<template>
    <div>
        <div class="tabTit fs-3 container d-flex align-items-center justify-content-center border-bottom border-secondary wow animate__bounceInDown">
            <span>Game Ecosystem</span>
        </div>
        <div class="horseBox container d-flex flex-wrap">
            <div class="b_item text-start" v-for="i in list" :key="i">
                <div class="b_title">{{ i.t1 }}</div>
                <div class="b_title1">{{ i.t2 }}</div>
                <img class="b_img" :src="i.img" alt="">
                <div class="b_isShowT shadow" v-if="i.isShowT">
                    Coming soon
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';


const list = ref([
    {
        t1: 'Crash',
        t2: 'Experience the thrill of skydiving!!!',
        img: require('../assets/image/game3.png'),
        isShowT: false,
    },
    {
        t1: 'DTX',
        t2: 'Dodge the killers and escape to safety.',
        img: require('../assets/image/game2.png'),
        isShowT: false,
    },
    {
        t1: 'Horse Racing',
        t2: 'Choose the best horse you believe in!',
        img: require('../assets/image/game.png'),
        isShowT: true
    },
    {
        t1: 'Ganesha Gold',
        t2: 'Hit the jackpot with AAA, waiting for you!!',
        img: require('../assets/image/game1.png'),
        isShowT: true
    },
    {
        t1: 'More Games',
        t2: 'On the way',
        img: require('../assets/image/game4.png'),
        isShowT: false
    },
])

</script>

<style lang="scss" scoped>

.horseBox {
    margin: 0 auto;

    .b_item {
        margin-left: 12px;
        margin-right: 12px;
        margin-bottom: 46px;
        position: relative;

        .b_title {
            font-size: 24px;
            margin-bottom: 9px;
        }

        .b_title1 {
            font-size: 14px;
            margin-bottom: 16px;
        }

        .b_img {
            width: 298px;
            height: 160px;
            border-radius: 8px;
        }

        .b_isShowT {
            width: 100%;
            height: 30px;
            position: absolute;
            font-size: 16px;
            background-color: #a48ed2;
            bottom: 30px;
            text-align: center;
            line-height: 30px;
        }
    }
}
</style>