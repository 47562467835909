<template>
    <div class="JoinUs">
        <div class="tabTit fs-3 container d-flex align-items-center justify-content-center wow animate__bounceInDown">
            <span>Earn Rewards</span>
        </div>
        <div class="JoinUs_box d-flex ">
            <div class="box_left wow animate__zoomIn">
                <div class="bl_nei text-start">
                    <div class="bln_tit">Airdrop Rewards</div>
                    <div class="bln_tit1">Register and receive 1,000 HORSE tokens. As the number of users grows, the
                        airdrop amount will decrease, making HORSE more valuable. All community players will co-create,
                        share, and win together. <a href="https://t.me/Horsegamefi/1">(Join now)</a></div>
                </div>
                <div class="jiaoBiao">#1</div>
            </div>
            <div class="box_right">
                <div class="br_top wow animate__zoomIn">
                    <div class="jiaoBiao">#2</div>
                    <div class="br_nei text-start">
                        <div class="brn_tit">Share Rewards</div>
                        <div class="brn_tit1">Share with one person and earn 10% of the new user's airdrop. Share with
                            two people and earn 20%. Share with three people and earn 30%, and so on. Share with ten
                            people and earn 100%! There are rewards for all levels, so let’s go wild! Achieve the future
                            together and make HORSE take off. <a href="https://t.me/Horsegamefi/1">(Join now)</a></div>
                    </div>
                </div>
                <div class="br_buttom d-flex">
                    <div class="brb_left wow animate__zoomIn">
                        <div class="jiaoBiao">#3</div>
                        <div class="brb_nei text-start">
                            <div class="brbln_tit">KOL Join Us & Win Genesis NFT</div>
                            <div class="brbln_tit1">KOLs can choose to become our ambassadors and receive Genesis NFTs.
                                These NFTs not only generate HORSE tokens but also provide community voting rights,
                                allowing participation in all future project decisions. Join now. <a
                                    href="https://t.me/Horsegamefi/1">(Join now)</a></div>
                        </div>
                    </div>
                    <div class="brb_right wow animate__zoomIn">
                        <div class="jiaoBiao">#4</div>
                        <div class="brb_nei text-start">
                            <div class="brbln_tit">Game Rewards</div>
                            <div class="brbln_tit1">Participate in NFT purchases to earn more HORSE. We are continually
                                improving the blockchain game ecosystem. Participate or share the blockchain games to
                                receive additional rewards! Join now. <a href="https://t.me/Horsegamefi/1">(Join
                                    now)</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.JoinUs {

    .JoinUs_box {
        width: 1200px;
        margin: 0 auto;

        .jiaoBiao {
            position: absolute;
            top: 20px;
            left: 20px;
        }

        .box_left {
            width: 435px;
            height: 560px;
            border-radius: 18px;
            background-image: url(../assets/image/1.png);
            background-size: 100% 100%;
            margin-right: 16px;
            position: relative;

            .bl_nei {
                width: 329px;
                position: absolute;
                left: 50%;
                bottom: 68px;
                margin-left: -164.5px;

                .bln_tit {
                    font-size: 26px;
                    margin-bottom: 26px;
                }

                .bln_tit1 {
                    font-size: 18px;
                    color: rgba($color: #fff, $alpha: 0.5);
                }
            }
        }

        .box_right {
            .br_top {
                width: 747px;
                height: 310px;
                border-radius: 18px;
                background-image: url(../assets/image/2.png);
                background-size: 100% 100%;
                position: relative;

                .br_nei {
                    position: absolute;
                    bottom: 36px;
                    left: 45px;

                    .brn_tit {
                        font-size: 16px;
                        margin-bottom: 36px;
                    }

                    .brn_tit1 {
                        font-size: 12px;
                        color: rgba($color: #fff, $alpha: 0.5);
                        width: 323px;
                        height: 115px;
                    }
                }
            }

            .br_buttom {
                margin-top: 13px;

                .brb_nei {
                    position: absolute;
                    bottom: 50px;
                    left: 24px;

                    .brbln_tit {
                        font-size: 16px;
                        margin-bottom: 28px;
                    }

                    .brbln_tit1 {
                        width: 287px;
                        height: 50px;
                        font-size: 12px;
                        color: rgba($color: #fff, $alpha: 0.5);
                    }
                }

                .brb_left {
                    width: 362px;
                    height: 237px;
                    border-radius: 18px;
                    background-image: url(../assets/image/3.png);
                    background-size: 100% 100%;
                    margin-right: 24px;
                    position: relative;
                }

                .brb_right {
                    width: 362px;
                    height: 237px;
                    border-radius: 18px;
                    background-image: url(../assets/image/4.png);
                    background-size: 100% 100%;
                    position: relative;
                }
            }
        }
    }
}


@media (max-width: 1199.98px) {
    $num: 1.5;

    .JoinUs {

        .JoinUs_box {
            width: calc(1200px / $num);

            .jiaoBiao {
                top: calc(20px / $num);
                left: calc(20px / $num);
            }

            .box_left {
                width: calc(435px / $num);
                height: calc(560px / $num);
                border-radius: calc(18px / $num);
                margin-right: calc(16px / $num);

                .bl_nei {
                    width: calc(329px / $num);
                    bottom: calc(68px / $num);
                    margin-left: calc(-164.5px / $num);

                    .bln_tit {
                        font-size: calc(26px / $num);
                        margin-bottom: calc(26px / $num);
                    }

                    .bln_tit1 {
                        font-size: calc(18px / $num);
                    }
                }
            }

            .box_right {
                .br_top {
                    width: calc(747px / $num);
                    height: calc(310px / $num);
                    border-radius: calc(18px / $num);

                    .br_nei {
                        bottom: calc(36px / $num);
                        left: calc(45px / $num);

                        .brn_tit {
                            font-size: calc(16px / $num);
                            margin-bottom: calc(36px / $num);
                        }

                        .brn_tit1 {
                            font-size: calc(12px / $num);
                            width: calc(323px / $num);
                            height: calc(115px / $num);
                        }
                    }
                }

                .br_buttom {
                    margin-top: calc(13px / $num);

                    .brb_nei {
                        bottom: calc(50px / $num);
                        left: calc(24px / $num);

                        .brbln_tit {
                            font-size: calc(16px / $num);
                            margin-bottom: calc(28px / $num);
                        }

                        .brbln_tit1 {
                            width: calc(287px / $num);
                            height: calc(50px / $num);
                            font-size: calc(12px / $num);
                        }
                    }

                    .brb_left {
                        width: calc(362px / $num);
                        height: calc(237px / $num);
                        border-radius: calc(18px / $num);
                        margin-right: calc(24px / $num);
                    }

                    .brb_right {
                        width: calc(362px / $num);
                        height: calc(237px / $num);
                        border-radius: calc(18px / $num);
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    $num: 2;

    .JoinUs {

        .JoinUs_box {
            width: calc(1200px / $num);

            .jiaoBiao {
                top: calc(20px / $num);
                left: calc(20px / $num);
            }

            .box_left {
                width: calc(435px / $num);
                height: calc(560px / $num);
                border-radius: calc(18px / $num);
                margin-right: calc(16px / $num);

                .bl_nei {
                    width: calc(329px / $num);
                    bottom: calc(68px / $num);
                    margin-left: calc(-164.5px / $num);

                    .bln_tit {
                        font-size: calc(26px / $num);
                        margin-bottom: calc(26px / $num);
                    }

                    .bln_tit1 {
                        font-size: calc(18px / $num);
                    }
                }
            }

            .box_right {
                .br_top {
                    width: calc(747px / $num);
                    height: calc(310px / $num);
                    border-radius: calc(18px / $num);

                    .br_nei {
                        bottom: calc(36px / $num);
                        left: calc(45px / $num);

                        .brn_tit {
                            font-size: calc(16px / $num);
                            margin-bottom: calc(36px / $num);
                        }

                        .brn_tit1 {
                            font-size: calc(12px / $num);
                            width: calc(323px / $num);
                            height: calc(115px / $num);
                        }
                    }
                }

                .br_buttom {
                    margin-top: calc(13px / $num);

                    .brb_nei {
                        bottom: calc(50px / $num);
                        left: calc(24px / $num);

                        .brbln_tit {
                            font-size: calc(16px / $num);
                            margin-bottom: calc(28px / $num);
                        }

                        .brbln_tit1 {
                            width: calc(287px / $num);
                            height: calc(50px / $num);
                            font-size: calc(12px / $num);
                        }
                    }

                    .brb_left {
                        width: calc(362px / $num);
                        height: calc(237px / $num);
                        border-radius: calc(18px / $num);
                        margin-right: calc(24px / $num);
                    }

                    .brb_right {
                        width: calc(362px / $num);
                        height: calc(237px / $num);
                        border-radius: calc(18px / $num);
                    }
                }
            }
        }
    }
}

@media (max-width: 767.98px) {
    $num: 3;

    .JoinUs {

        .JoinUs_box {
            width: calc(1200px / $num);

            .jiaoBiao {
                top: calc(20px / $num);
                left: calc(20px / $num);
            }

            .box_left {
                width: calc(435px / $num);
                height: calc(560px / $num);
                border-radius: calc(18px / $num);
                margin-right: calc(16px / $num);

                .bl_nei {
                    width: calc(329px / $num);
                    bottom: calc(68px / $num);
                    margin-left: calc(-164.5px / $num);

                    .bln_tit {
                        font-size: calc(26px / $num);
                        margin-bottom: calc(26px / $num);
                    }

                    .bln_tit1 {
                        font-size: calc(18px / $num);
                    }
                }
            }

            .box_right {
                .br_top {
                    width: calc(747px / $num);
                    height: calc(310px / $num);
                    border-radius: calc(18px / $num);

                    .br_nei {
                        bottom: calc(36px / $num);
                        left: calc(45px / $num);

                        .brn_tit {
                            font-size: calc(16px / $num);
                            margin-bottom: calc(36px / $num);
                        }

                        .brn_tit1 {
                            font-size: calc(12px / $num);
                            width: calc(323px / $num);
                            height: calc(115px / $num);
                        }
                    }
                }

                .br_buttom {
                    margin-top: calc(13px / $num);

                    .brb_nei {
                        bottom: calc(50px / $num);
                        left: calc(24px / $num);

                        .brbln_tit {
                            font-size: calc(16px / $num);
                            margin-bottom: calc(28px / $num);
                        }

                        .brbln_tit1 {
                            width: calc(287px / $num);
                            height: calc(50px / $num);
                            font-size: calc(12px / $num);
                        }
                    }

                    .brb_left {
                        width: calc(362px / $num);
                        height: calc(237px / $num);
                        border-radius: calc(18px / $num);
                        margin-right: calc(24px / $num);
                    }

                    .brb_right {
                        width: calc(362px / $num);
                        height: calc(237px / $num);
                        border-radius: calc(18px / $num);
                    }
                }
            }
        }
    }
}

@media (max-width: 576px) {
    $num: 3.5;

    .JoinUs {

        .JoinUs_box {
            width: 100%;
            display: block !important;

            .jiaoBiao {
                top: 20px;
                left: 20px;
            }

            .box_left {
                width: 331px;
                height: 427px;
                margin: 0 auto;

                .bl_nei {
                    width: 282px;
                    bottom: 68px;
                    margin-left: -141px;

                    .bln_tit {
                        font-size: 16px;
                        margin-bottom: 10px;
                    }

                    .bln_tit1 {
                        font-size: 12px;
                    }
                }
            }

            .box_right {
                .br_top {
                    width: 331px;
                    height: 261px;
                    margin: 0 auto;
                    border-radius: 18px;
                    margin-top: 10px;

                    .br_nei {
                        bottom: 36px;
                        left: 20px;

                        .brn_tit {
                            font-size: 16px;
                            margin-bottom: 5px;
                        }

                        .brn_tit1 {
                            font-size: 12px;
                            width: 223px;
                            height: 131px;
                        }
                    }
                }

                .br_buttom {
                    display: block !important;
                    margin-top: 10px;

                    .brb_nei {
                        bottom: 10px;
                        left: 20px;

                        .brbln_tit {
                            font-size: 16px;
                            margin-bottom: 5px;
                        }

                        .brbln_tit1 {
                            width: 304px;
                            height: auto;
                            font-size: 12px;
                        }
                    }

                    .brb_left {
                        width: 331px;
                        height: 180px;
                        border-radius: 18px;
                        margin-right: 0;
                        margin: 0 auto;
                    }

                    .brb_right {
                        width: 331px;
                        height: 174px;
                        border-radius: 18px;
                        margin: 0 auto;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}
</style>