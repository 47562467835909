<template>
    <div>
        <div
            class="tabTit fs-3 container d-flex align-items-center justify-content-center border-bottom border-secondary wow animate__bounceInDown">
            <img class="tabTit_img" src="https://static.horse8.org/app/m/2.0/money/horse.png" alt="">
            <span>Horse Token Economics</span>
        </div>
        <div class="horseBox container d-flex align-items-center justify-content-center">
            <div class="horseBox_li">
                <div class="left_t wow animate__bounceInDown">
                    <span>Horse Token, Total Supply: 100,000,000,000</span>
                </div>
                <img class="left_i" src="../assets/image/horse.png" alt="">
            </div>
            <div class="horseBox_li">
                <div class="right_l d-flex align-items-center justify-content-center" v-for="i, x in list" :key="x">
                    <img class="right_i" :src="i.img" alt="">
                    <div class="right_j">
                        <div class="text-start">{{ i.text }}</div>
                        <div class="right_jb">
                            <div class="right_jn" :style="{ width: `${i.num}` }"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';


const list = ref([
    {
        text: 'Airdrop 5%',
        num: '5%',
        img: require('../assets/image/00001.png')
    },
    {
        text: 'Ambassadors 5%',
        num: '5%',
        img: require('../assets/image/00005.png')
    },
    {
        text: 'NFT Mining 40%',
        num: '40%',
        img: require('../assets/image/00002.png')
    },
    {
        text: 'Staking Mining 17%',
        num: '17%',
        img: require('../assets/image/00007.png')
    },
    {
        text: 'TON GameFi 15%',
        num: '15%',
        img: require('../assets/image/00003.png')
    },
    {
        text: 'Pool Burn 13%',
        num: '13%',
        img: require('../assets/image/00006.png')
    },
    {
        text: 'Technical Team 5%',
        num: '5%',
        img: require('../assets/image/00004.png')
    }
])
</script>

<style lang="scss" scoped>

.horseBox {
    .horseBox_li {
        width: calc(600px / 1);
        position: relative;
        text-align: center;

        .left_t {
            width: 100%;
            font-size: calc(40px / 1);
            position: absolute;
            top: 0;
            left: 0;
            text-align: center;
        }

        .left_i {
            height: calc(452px / 1);
        }

        .right_l {
            width: calc(589px / 1);
            height: calc(58px / 1);
            margin-bottom: calc(68px / 1);

            &:last-child {
                margin-bottom: 0;
            }

            .right_i {
                width: calc(38px / 1);
                height: calc(38px / 1);
            }

            .right_j {
                font-size: calc(26px / 1);
                margin-left: calc(22px / 1);

                .right_jb {
                    width: calc(529px / 1);
                    height: calc(10px / 1);
                    background-color: rgba($color: #fff, $alpha: 0.1);
                    border-radius: calc(5px / 1);

                    .right_jn {
                        width: 5%;
                        height: calc(10px / 1);
                        background-image: linear-gradient(90deg,
                                #f70fff 0%,
                                #12d6df 100%),
                            linear-gradient(#ffffff,
                                #ffffff);
                        background-blend-mode: normal,
                            normal;
                        border-radius: calc(5px / 1);
                    }
                }
            }
        }
    }
}

@media (max-width: 1199.98px) {
    $num: 1.5;

    .horseBox {
        .horseBox_li {
            width: calc(600px / $num);

            .left_t {
                font-size: calc(40px / $num);
            }

            .left_i {
                height: calc(452px / $num);
            }

            .right_l {
                width: calc(589px / $num);
                height: calc(58px / $num);
                margin-bottom: calc(68px / $num);

                .right_i {
                    width: calc(38px / $num);
                    height: calc(38px / $num);
                }

                .right_j {
                    font-size: calc(26px / $num);
                    margin-left: calc(22px / $num);

                    .right_jb {
                        width: calc(529px / $num);
                        height: calc(10px / $num);
                        border-radius: calc(5px / $num);

                        .right_jn {
                            height: calc(10px / $num);
                            border-radius: calc(5px / $num);
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    $num: 2;

    .horseBox {
        .horseBox_li {
            width: calc(600px / $num);

            .left_t {
                font-size: calc(40px / $num);
            }

            .left_i {
                height: calc(452px / $num);
            }

            .right_l {
                width: calc(589px / $num);
                height: calc(58px / $num);
                margin-bottom: calc(68px / $num);

                .right_i {
                    width: calc(38px / $num);
                    height: calc(38px / $num);
                }

                .right_j {
                    font-size: calc(26px / $num);
                    margin-left: calc(22px / $num);

                    .right_jb {
                        width: calc(529px / $num);
                        height: calc(10px / $num);
                        border-radius: calc(5px / $num);

                        .right_jn {
                            height: calc(10px / $num);
                            border-radius: calc(5px / $num);
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 767.98px) {
    $num: 3;

    .horseBox {
        .horseBox_li {
            width: calc(600px / $num);

            .left_t {
                font-size: calc(40px / $num);
            }

            .left_i {
                height: calc(452px / $num);
            }

            .right_l {
                width: calc(589px / $num);
                height: calc(58px / $num);
                margin-bottom: calc(68px / $num);

                .right_i {
                    width: calc(38px / $num);
                    height: calc(38px / $num);
                }

                .right_j {
                    font-size: calc(26px / $num);
                    margin-left: calc(22px / $num);

                    .right_jb {
                        width: calc(529px / $num);
                        height: calc(10px / $num);
                        border-radius: calc(5px / $num);

                        .right_jn {
                            height: calc(10px / $num);
                            border-radius: calc(5px / $num);
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 576px) {
    $num: 3.5;

    .horseBox {
        .horseBox_li {
            width: calc(600px / $num);

            .left_t {
                font-size: calc(40px / $num);
            }

            .left_i {
                height: calc(452px / $num);
            }

            .right_l {
                width: calc(589px / $num);
                height: calc(58px / $num);
                margin-bottom: calc(68px / $num);

                .right_i {
                    width: calc(38px / $num);
                    height: calc(38px / $num);
                }

                .right_j {
                    font-size: calc(26px / $num);
                    margin-left: calc(22px / $num);

                    .right_jb {
                        width: calc(529px / $num);
                        height: calc(10px / $num);
                        border-radius: calc(5px / $num);

                        .right_jn {
                            height: calc(10px / $num);
                            border-radius: calc(5px / $num);
                        }
                    }
                }
            }
        }
    }
}
</style>